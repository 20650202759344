import React from 'react'
import Layout from '../components/layout'
import { Icon, InputAdornment, ListItem, ListItemIcon, ListItemText, OutlinedInput } from '@material-ui/core'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import IconButton from '@material-ui/core/IconButton'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import useGameConfig from '../hooks/useGameConfig'
import useSingleToast from '../hooks/useSingleToast'
import { useLazyQuery } from '@apollo/client'
import { GET_PLAYER_BY_CODE_QUERY } from '../graphql/queries'
import useNetwork from '../hooks/useNetwork'
import usePdfGenerator from '../hooks/usePdfGenerator'
import useUser from '../hooks/useUser'
import { navigate } from 'gatsby'
import { v4 as uuidv4 } from 'uuid'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import useIsClient from '../hooks/useIsClient'
import {SimpleDialog} from '../components/SimpleDialog'

function Registered ({ location: { state } }) {
  const { texts: { notificationTexts } } = useGameConfig()
  const { addSingleToast } = useSingleToast()
  const isOnline = useNetwork()
  const { generateAndDownloadPdf } = usePdfGenerator()
  const { gameConfig } = useGameConfig()
  const { setRawUser } = useUser()

  const [open, setOpen] = React.useState(false);

const handleClose = (value) => {
    setOpen(false);
    navigate('/app/overview')
  };


  const [logIn] = useLazyQuery(
    GET_PLAYER_BY_CODE_QUERY, {
      fetchPolicy: 'network-only',
      onCompleted: r => {
        console.log('r', r)
        setRawUser(r, 'synced')
        setOpen(true);
      }
    }
  )
  const { isClient } = useIsClient()

  if (!isClient) {
    return null
  }

  if (!state?.code) {
    navigate('/login')
    return null
  }

  const { code, nickName } = state

  const handleFocus = event => {
    if (event.target.select) {
      event.target.select()
    }
  }

  const doLogin = () => {
    if (isOnline) {

      logIn({
        variables: {
          clientMutationId: uuidv4(),
          code: code,
        }
      })

    } else {
      addSingleToast(
        <div>
          <p>{notificationTexts.youMustBeOnline}</p>
        </div>,
        { appearance: 'error' })
    }
  }

  return (
    <Layout>
      <h1>{notificationTexts.finishRegistration}</h1>
      {code && (
        <OutlinedInput
          label={notificationTexts.yourCode}
          notched={false}
          defaultValue={code}
          readOnly={true}
          variant="outlined"
          onFocus={handleFocus}
          onBlur={handleFocus}
          onClick={handleFocus}
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <CopyToClipboard text={code} onCopy={() => {
                addSingleToast(
                  <div>
                    <p>{notificationTexts.codeCopied}</p>
                  </div>,
                  { appearance: 'success' })
              }}>
                <IconButton>
                  <FileCopyIcon/>
                </IconButton>
              </CopyToClipboard>
            </InputAdornment>
          }
        />
      )}
      <p>{gameConfig.welcomePage.codeGenerierenLightbox}</p>
      <div>
        <List component="nav" aria-label="main mailbox folders">
          {gameConfig.welcomePage.registeredTipList.map(li => (
            <ListItem>
              <ListItemIcon>
                <Icon>{li.icon}</Icon>
              </ListItemIcon>
              <ListItemText primary={li.text}/>
            </ListItem>
          ))}
        </List>
      </div>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => {
          let text = notificationTexts.pdfText
            .replace('${nickName}', nickName)
            .replace('${code}', code)
          generateAndDownloadPdf(text, 'sdg-zugangscode.pdf')
          doLogin()
        }}
      >
        {notificationTexts.downloadCodeAndLogin}
      </Button>
      <SimpleDialog open={open} onClose={handleClose} />
    </Layout>
  )
}

export default Registered
