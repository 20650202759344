import * as React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";

export function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Los geht's!</DialogTitle>
      <DialogContent>
          <DialogContentText id="alert-dialog-description">
              Sammle Punkte für den Wandel, indem du die Aufgaben rund um die 17 Ziele nachhaltiger Entwicklung erledigst. <br />
              Weitere Hinweise zum Spiel findest du im Menü unter "Spielanleitung". Viel Spaß beim WIRKELn!
          </DialogContentText>
      </DialogContent>
      <DialogActions>
          <Button onClick={handleClose}>Okay</Button>
      </DialogActions>
    </Dialog>
  );
}
